import { AvatarButtonGroup } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { components, OptionProps } from 'react-select'
import { ManagerUserSelectOption } from '../manager-user-select.types'

export const Option = (props: OptionProps<ManagerUserSelectOption, false>) => {
  const { data, isFocused, isSelected, label } = props

  const { users } = data

  return (
    <components.Option
      {...props}
      className={cn(
        isFocused && 'bg-gray-50',
        isSelected && !isFocused && 'bg-transparent',
        'hover:!bg-gray-50 hover:cursor-pointer px-4 py-3 flex flex-row items-center gap-x-2 text-gray-900'
      )}
    >
      <AvatarButtonGroup
        avatarClassName="border-gray-100 border-px border-solid"
        avatars={avatarGroupProps(users, { stateTooltip: true })}
        className="mr-1"
        limit={2}
        limitClassName="bg-gray-100 font-normal text-gray-900"
        limitStyle="avatar"
        size="xs"
      />
      <span data-selected={isSelected}>{label}</span>
    </components.Option>
  )
}
