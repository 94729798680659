import { components, GroupHeadingProps } from 'react-select'
import * as React from 'react'
import cn from 'classnames'
import { ManagerUserSelectOption } from '../manager-user-select.types'

export const GroupHeading = (
  props: GroupHeadingProps<ManagerUserSelectOption, false>
) => {
  const { className, ...restProps } = props

  return (
    <components.GroupHeading
      {...restProps}
      className={cn(
        'text-xs text-gray-600 font-bold mt-1.5 mb-2 px-3',
        className
      )}
    />
  )
}
