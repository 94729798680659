import { Dropdown } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ReactSelect from 'react-select'
import {
  ManagerUserSelectProps,
  ManagerUserSelectOption,
} from './manager-user-select.types'
import { ManagerUserSelectVm } from './manager-user-select-vm'
import { TriggerContent } from './components/trigger'
import * as Components from './components'

export const ManagerUserSelect = observer((props: ManagerUserSelectProps) => {
  const {
    defaultValue,
    menuIsOpen,
    onChange,
    showYou = true,
    showEverybody = false,
    triggerContent,
    user,
    disabled = false,
    value: externalValue,
  } = props

  const vm = React.useMemo(() => {
    return new ManagerUserSelectVm(user, showYou, showEverybody)
  }, [user, showYou, showEverybody])

  const [open, setOpen] = React.useState(!!menuIsOpen)

  const [value, setValue] = React.useState<ManagerUserSelectOption | undefined>(
    vm.defaultValue(externalValue || defaultValue)
  )

  React.useEffect(() => {
    setValue(vm.defaultValue(externalValue || defaultValue))
  }, [vm, externalValue, defaultValue])

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger
        className="h-[34px]"
        data-testid="manager-user-select-trigger"
        variant="styled"
        disabled={disabled}
      >
        <TriggerContent triggerContent={triggerContent} value={value} />
      </Dropdown.Trigger>
      <Dropdown.Content
        align="start"
        avoidCollisions={false}
        forceMount={menuIsOpen || undefined}
        className="font-normal"
      >
        <ReactSelect
          closeMenuOnSelect
          components={Components}
          menuIsOpen
          isMulti={false}
          onChange={(newValue) => {
            if (!newValue) return
            onChange?.(newValue?.value)
            setValue(newValue)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Escape') setOpen(false)
          }}
          options={vm.options}
          styles={{
            groupHeading: () => ({}),
            menu: () => ({}),
          }}
          value={value}
        />
      </Dropdown.Content>
    </Dropdown.Root>
  )
})
