import React from 'react'
import { User } from 'store/modules/users'

export type ManagerUserSelectProps = {
  /**
   * Value of the default select option
   */
  defaultValue?: string
  /**
   * Whether the select dropdown should be forced open. This should only be used for testing/internal purposes
   */
  menuIsOpen?: boolean
  /**
   * Custom onChange handler to use values from the select externally. This shouldn't be needed if you're using this within a form as this component already exposes hidden input values
   */
  onChange?: (value: string) => void
  /**
   * Whether to show the 'You and' options in the select dropdown
   * */
  showYou?: boolean
  /**
   * Whether to show the 'Everybody' option in the select dropdown
   * */
  showEverybody?: boolean
  /**
   * Content to show on the empty state for the skill select trigger
   * @default 'Select'
   */
  triggerContent?: React.ReactNode
  user: User
  disabled?: boolean
  value?: ManagerSelectValue
}

export type ManagerUserSelectOption = {
  label: string
  users: User[]
  value: ManagerSelectValue | string
}

export type GroupedManagerUserSelectOption = {
  label: string
  options: ManagerUserSelectOption[]
}

export enum ManagerSelectValue {
  Everybody = 'everybody',
  AllReportingLine = 'all_reporting_line',
  DirectReports = 'direct_reports',
  YouAndAllReportingLine = 'you_and_all_reporting_line',
  YouAndDirectReports = 'you_and_direct_reports',
}
