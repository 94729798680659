import { User } from 'store/modules/users'
import {
  GroupedManagerUserSelectOption,
  ManagerSelectValue,
  ManagerUserSelectOption,
} from './manager-user-select.types'
import { store } from 'store/index'

export class ManagerUserSelectVm {
  constructor(
    private user: User,
    private showYou: boolean,
    private showEverybody = false
  ) {}

  defaultValue(value?: string) {
    if (value === undefined) {
      if (this.nonDirectReports.length > 0) {
        return this.youAndAllReportingLineUserSelectOption
      } else {
        return this.youAndDirectReportsUserSelectOption
      }
    }

    switch (value) {
      case ManagerSelectValue.Everybody:
        return this.everybodySelectOption
      case ManagerSelectValue.AllReportingLine:
        return this.allReportingLineSelectOption
      case ManagerSelectValue.DirectReports:
        return this.directReportsSelectOption
      case ManagerSelectValue.YouAndAllReportingLine:
        return this.youAndAllReportingLineUserSelectOption
      case ManagerSelectValue.YouAndDirectReports:
        return this.youAndDirectReportsUserSelectOption
      case this.user.id:
        return this.onlyYouUserSelectOption
      default:
        return this.groupedReportOptions(this.allReports).find(
          (option) => option.value === value
        )
    }
  }

  get options() {
    const options: (
      | GroupedManagerUserSelectOption
      | ManagerUserSelectOption
    )[] = []

    if (this.showEverybody) options.push(this.everybodySelectOption)

    if (this.nonDirectReports.length > 0 && this.showYou) {
      options.push(this.youAndAllReportingLineUserSelectOption)
    }

    if (this.showYou) options.push(this.youAndDirectReportsUserSelectOption)

    if (this.nonDirectReports.length > 0) {
      options.push(this.allReportingLineSelectOption)
    }

    options.push(this.directReportsSelectOption)

    if (this.showYou) options.push(this.onlyYouUserSelectOption)

    options.push({
      label: 'Your reports',
      options: this.groupedReportOptions(this.directReports),
    })

    if (this.nonDirectReports.length > 0) {
      options.push({
        label: 'Your reporting line',
        options: this.groupedReportOptions(this.nonDirectReports),
      })
    }

    return options
  }

  private get allReports() {
    return this.user.allReports
  }

  private get directReports() {
    return this.user.directReports
  }

  private get nonDirectReports() {
    return this.user.nonDirectReports
  }

  private get everybodySelectOption() {
    return {
      label: 'Everybody',
      users: store.users.active,
      value: ManagerSelectValue.Everybody,
    }
  }

  private get allReportingLineSelectOption() {
    return {
      label: 'All reporting line',
      users: this.allReports,
      value: ManagerSelectValue.AllReportingLine,
    }
  }

  private get directReportsSelectOption() {
    return {
      label: 'Direct reports',
      users: this.directReports,
      value: ManagerSelectValue.DirectReports,
    }
  }

  private get onlyYouUserSelectOption() {
    return {
      label: 'Only you',
      users: [this.user],
      value: this.user.id,
    }
  }

  private get youAndAllReportingLineUserSelectOption() {
    return {
      label: 'You and all reporting line',
      users: this.user.isOwnManager
        ? this.allReports
        : [this.user].concat(this.allReports),
      value: ManagerSelectValue.YouAndAllReportingLine,
    }
  }

  private get youAndDirectReportsUserSelectOption() {
    return {
      label: 'You and direct reports',
      users: this.user.isOwnManager
        ? this.directReports
        : [this.user].concat(this.directReports),
      value: ManagerSelectValue.YouAndDirectReports,
    }
  }

  private groupedReportOptions(reports: User[]) {
    return reports.map((report) => {
      return { label: report.fullName, users: [report], value: report.id }
    })
  }
}
