import { AvatarButtonGroup } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { ManagerUserSelectOption } from '../manager-user-select.types'

export type TriggerProps = {
  triggerContent?: React.ReactNode
  value?: ManagerUserSelectOption[] | ManagerUserSelectOption
}

export const TriggerContent = (props: TriggerProps) => {
  const { triggerContent = 'Select', value } = props

  let content = triggerContent

  if (value) {
    if (Array.isArray(value)) {
      if (value.length === 1) content = value[0].label
      if (value.length === 2) content = value.map((v) => v.label).join(', ')
      if (value.length > 2) content = `${value[0].label}, +${value.length - 1}`
    } else {
      content = <TriggerContentWithUsers value={value} />
    }
  }

  const hasValue = Array.isArray(value) ? value.length > 0 : value

  return <div className={cn(hasValue && 'text-gray-900')}>{content}</div>
}

const TriggerContentWithUsers: React.VFC<{
  value: ManagerUserSelectOption
}> = (props) => {
  const { value } = props

  return (
    <div className="flex gap-1 items-center">
      <AvatarButtonGroup
        avatarClassName="border-gray-100 border-px border-solid"
        avatars={avatarGroupProps(value.users, { stateTooltip: true })}
        className="mr-1"
        limit={2}
        limitClassName="bg-gray-100 font-normal text-gray-900"
        limitStyle="avatar"
        size="xs"
      />
      <div className="text-gray-900">{value.label}</div>
    </div>
  )
}
